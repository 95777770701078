import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import EllipsisWithTooltip from 'shared/ui/molecules/ellipsisWithTooltip';
import {formatLocation} from 'candidate/shared/job/location';
import Location from './location';
import styles from './styles.scss';

const LocationsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    countryCode: PropTypes.string,
    display: PropTypes.string,
    hidden: PropTypes.boolean,
    region: PropTypes.string
  })
).isRequired;

const Content = ({dataUiPrefix, locations}) => {
  if (!locations.length) {
    return null;
  }

  return locations.map((location, index) => (
    <EllipsisWithTooltip
      data-ui={`${dataUiPrefix}-tooltip`}
      key={index}
      tooltipProps={{title: formatLocation(location)}}
    >
      <span>
        <Location {...location} />
      </span>
    </EllipsisWithTooltip>
  ));
};

Content.propTypes = {
  dataUiPrefix: PropTypes.string,
  locations: LocationsPropType
};

const MultipleLocations = ({className, dataUi, id, locations}) => (
  <div className={clsx(className, styles.container)} data-ui={dataUi} id={id}>
    <Content dataUiPrefix={dataUi} locations={locations} />
  </div>
);

MultipleLocations.propTypes = {
  className: PropTypes.string,
  dataUi: PropTypes.string.isRequired,
  id: PropTypes.string,
  locations: LocationsPropType
};

export default MultipleLocations;
